* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  position: fixed;
  overflow: hidden;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
}

.draggable [id^="draggable-paper-handle"] {
  cursor: default;
}
